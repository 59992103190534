import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';

const IndexPage = () => (
  <Layout>
    <h1>Bryllup - Ønskeliste</h1>
    <ul>
      <li>Kage</li>
      <li>Græsslåmaskine (robot?)</li>
    </ul>
    <Link to="/">Tilbage til oversigt</Link>
  </Layout>
);

export default IndexPage;
